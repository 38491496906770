@import "https://fonts.googleapis.com/css2?family=Lato:wght@300;700&family=Noto+Sans+Mono&family=Roboto&display=swap";
* {
  box-sizing: border-box;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
  font-family: Noto Sans Mono, monospace;
}

header {
  height: 100%;
  height: 100vh;
  width: 100%;
  padding-top: 100px;
  display: flex;
}

header h1, h2 {
  letter-spacing: 11px;
  margin: auto;
  transition: all 4s ease-in-out;
}

.container {
  max-width: 50%;
  margin: auto;
}

section {
  min-height: 40vh;
  align-content: center;
  place-items: center;
  display: grid;
}

section:first-child {
  min-height: 40vh;
  align-content: center;
  place-items: center;
  margin-top: 10vh;
}

.clicked {
  opacity: .1;
  letter-spacing: 13px;
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transition: all 2s;
}

.show {
  opacity: 1;
  filter: blur();
}

footer {
  text-align: center;
  color: #555;
  background-color: #f8f8f8;
  padding: 20px;
  font-family: Lato, sans-serif;
  font-size: 14px;
}

.members-container {
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30vh;
  display: flex;
}

.members {
  padding: 15px;
}

.members img {
  height: 150px;
  width: 150px;
  filter: grayscale();
  object-fit: cover;
  border-radius: 50%;
  flex-direction: row;
  display: flex;
}

.member-hidden {
  opacity: 0;
  filter: blur(5px);
  transition: all 1s;
  transform: translateX(-100%);
}

.member-show {
  opacity: 1;
  filter: blur();
  transform: translateX(0);
}

.members:nth-child(2) {
  transition-delay: .2s;
}

.members:nth-child(3) {
  transition-delay: .4s;
}

.members:nth-child(4) {
  transition-delay: .6s;
}

.members:nth-child(5) {
  transition-delay: .8s;
}

#menu {
  width: 260px;
  height: 100%;
  border-right: 2px solid #5555554d;
  align-items: center;
  transition: left .3s;
  display: flex;
  position: fixed;
  top: 0;
  left: -300px;
  box-shadow: 0 8px 12px #0006;
}

#menu.open {
  left: 0;
}

#menu ul {
  padding: 20px;
  list-style: none;
}

#menu ul li {
  margin: 60px 0;
}

#menu ul li a {
  color: #555;
  font-family: Noto Sans Mono, monospace;
  font-size: 20px;
  text-decoration: none;
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}

@media screen and (width <= 768px) {
  header {
    padding-top: 50px;
  }

  header h1, h2 {
    font-size: 24px;
  }

  .container {
    max-width: 90%;
  }

  section {
    min-height: 30vh;
  }

  .members img {
    height: 100px;
    width: 100px;
  }

  .members-container {
    margin-bottom: 20vh;
  }
}

/*# sourceMappingURL=index.1261262f.css.map */
